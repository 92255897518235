<template>
  <div>
    <div>
      <b-row class="bg-color">
        <b-col cols="6">
          <h5>
            <b-badge variant="light-success"
              >{{
                form.movie_information.title &&
                "Movie Name: " + form.movie_information.title + " -"
              }}
              Movie Unique ID :
              {{
                !movie_id && !isNaN(movie_id)
                  ? newMovieUniqueId
                  : form.movie_information.unique
              }}</b-badge
            >
          </h5>
        </b-col>
        <b-col style="justify-content: right; align-items: right" cols="6">
          <b-form-group label="Show on website">
            <b-form-checkbox
              v-model="form.movie_information.website_visible"
              switch
            />
          </b-form-group>
        </b-col>
      </b-row>

      <div>
        <b-tabs pills v-model="activeTabIndex">
          <b-tab title="Preview" :disabled="movie_id ? false : true">
            <movie-details></movie-details>
          </b-tab>
          <b-tab title="Movie Info">
            <b-card border-variant="primary">
              <b-card-text>
                <b-row>
                  <b-col md="4">
                    <b-form-group
                      label="Original Title"
                      label-for="mc-original-title"
                    >
                      <b-form-input
                        id="mc-original-title"
                        v-model="form.movie_information.title"
                        placeholder="  Original Title"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="International Title"
                      label-for="mc-international-title"
                    >
                      <b-form-input
                        id="mc-international-title"
                        v-model="form.movie_information.international_title"
                        placeholder="  International Title"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Type" label-for="mc-type">
                      <v-select
                        id="mc-type"
                        label="lbl_name"
                        placeholder="Select"
                        :options="$store.state.master.type"
                        :reduce="(label) => label.lbl_id"
                        v-model="form.movie_information.type"
                        multiple
                        :close-on-select="false"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Year" label-for="mc-year">
                      <b-form-input
                        id="mc-year"
                        v-model="form.movie_information.year"
                        placeholder="  Year"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="Original Language"
                      label-for="mc-original_language"
                    >
                      <v-select
                        id="mc-original_language"
                        label="lbl_name"
                        placeholder="Select"
                        :options="$store.state.master.language"
                        :reduce="(label) => label.lbl_id"
                        v-model="form.movie_information.original_language"
                        multiple
                        :close-on-select="false"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Director" label-for="mc-director">
                      <v-select
                        id="mc-director"
                        label="lbl_name"
                        placeholder="Select"
                        :options="$store.state.master.director"
                        :reduce="(label) => label.lbl_id"
                        v-model="form.movie_information.director"
                        multiple
                        :close-on-select="false"
                      >
                        <template #no-options="data">
                          <b-button
                            @click="() => addLabel('director', data.search)"
                          >
                            Add New Director
                          </b-button>
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Cast" label-for="mc-cast">
                      <v-select
                        id="mc-cast"
                        label="lbl_name"
                        placeholder="Select"
                        :options="$store.state.master.cast"
                        :reduce="(label) => label.lbl_id"
                        v-model="form.movie_information.cast"
                        multiple
                        :close-on-select="false"
                      >
                        <template #no-options="data">
                          <b-button @click="() => addLabel('cast', data.search)"
                            >Add New Cast</b-button
                          >
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Genre" label-for="mc-cast">
                      <v-select
                        id="mc-cast"
                        label="lbl_name"
                        placeholder="Select"
                        :options="$store.state.master.genre"
                        :reduce="(label) => label.lbl_id"
                        v-model="form.movie_information.genres"
                        multiple
                        :close-on-select="false"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Country" label-for="mc-country">
                      <v-select
                        id="mc-country"
                        label="lbl_name"
                        placeholder="Select"
                        :options="$store.state.master.country"
                        :reduce="(label) => label.lbl_id"
                        v-model="form.movie_information.country"
                        multiple
                        :close-on-select="false"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Duration" label-for="mc-duration">
                      <b-form-input
                        id="mc-duration"
                        v-model="form.movie_information.duration"
                        placeholder="  Duration"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Age/Rating" label-for="mc-age">
                      <v-select
                        id="mc-age"
                        label="lbl_name"
                        placeholder="Select"
                        :options="$store.state.master.age"
                        :reduce="(label) => label.lbl_id"
                        v-model="form.movie_information.age"
                        multiple
                        :close-on-select="false"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="SUISA Number"
                      label-for="mc-suisan_number"
                    >
                      <b-form-input
                        id="mc-suisan_number"
                        v-model="form.movie_information.suisan_number"
                        placeholder="SUISA Number"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="Image Format"
                      label-for="mc-image_format"
                    >
                      <v-select
                        id="mc-image_format"
                        label="lbl_name"
                        placeholder="Select"
                        :options="$store.state.master.image_format"
                        :reduce="(label) => label.lbl_id"
                        v-model="form.movie_information.image_format"
                        multiple
                        :close-on-select="false"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="Sound Format"
                      label-for="mc-sound_format"
                    >
                      <v-select
                        id="mc-sound_format"
                        label="lbl_name"
                        placeholder="Select"
                        :options="$store.state.master.sound_format"
                        :reduce="(label) => label.lbl_id"
                        v-model="form.movie_information.sound_format"
                        multiple
                        :close-on-select="false"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="ISAN Number"
                      label-for="mc-isan_number"
                    >
                      <b-form-input
                        id="mc-isan_number"
                        v-model="form.movie_information.isan_number"
                        placeholder="  ISAN Number"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Weblink/IMDB" label-for="mc-weblink">
                      <b-form-input
                        id="mc-weblink"
                        v-model="form.movie_information.weblink"
                        placeholder="  Weblink/IMDB"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="Available Languages"
                      label-for="mc-avaliable_languages"
                    >
                      <v-select
                        id="mc-avaliable_languages"
                        label="lbl_name"
                        placeholder="Select"
                        :options="$store.state.master.language"
                        :reduce="(label) => label.lbl_id"
                        v-model="form.movie_information.avaliable_languages"
                        multiple
                        :close-on-select="false"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="Available Subtitles"
                      label-for="mc-avaliable_subTitle"
                    >
                      <v-select
                        id="mc-avaliable_subTitle"
                        label="lbl_name"
                        placeholder="Select"
                        :options="$store.state.master.language"
                        :reduce="(label) => label.lbl_id"
                        v-model="form.movie_information.avaliable_subTitle"
                        multiple
                        :close-on-select="false"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-text>
            </b-card>
          </b-tab>
          <b-tab title="Release Dates">
            <b-row>
              <b-col class="mb-1" md="3" cols="12">
                <b-form-checkbox @change="hideShowFilter"
                  ><b><u>show search filters</u></b></b-form-checkbox
                >
              </b-col>

              <b-col v-if="!filtersHidden" class="mb-1" md="3" cols="12">
                <treeselect
                  placeholder="Select Right"
                  class="fix-select"
                  :options="$store.state.master.rights_tree"
                  multiple
                  @input="onSearchRelease"
                  v-model="searchReleaseDateRight"
                />
              </b-col>
              <b-col v-if="!filtersHidden" class="mb-1" md="3" cols="12">
                <treeselect
                  placeholder="Select Territory"
                  class="fix-select"
                  :options="$store.state.master.territory_tree"
                  multiple
                  @input="onSearchRelease"
                  v-model="searchReleaseDateTerritory"
                />
              </b-col>
              <b-col v-if="!filtersHidden" class="mb-1" md="2" cols="12">
                <b-button
                  size="sm"
                  @click="onSearchRelease"
                  variant="outline-primary"
                  >Search</b-button
                >
              </b-col>
            </b-row>
            <b-card
              border-variant="primary"
              class="bg-color1 scroll"
              style="overflow-x: scroll"
            >
              <table>
                <tr>
                  <th>Rights</th>
                  <th>Territories</th>
                  <th style="min-width: 250px">Languages</th>
                  <th style="min-width: 150px">Planned Date</th>
                  <th style="min-width: 150px">Actual Date</th>

                  <th>Action</th>
                </tr>
                <tr
                  v-for="(release, release_index) in form.movie_release"
                  :key="'movie_release_' + release_index"
                >
                  <td>
                    <treeselect
                      class="fix-select"
                      :options="$store.state.master.rights_tree"
                      multiple
                      v-model="release.right"
                    />
                  </td>
                  <td>
                    <treeselect
                      class="fix-select"
                      :options="$store.state.master.territory_tree"
                      multiple
                      v-model="release.territory"
                    />
                  </td>
                  <td>
                    <v-select
                      class="fix-select"
                      id="mc-release_languages"
                      label="lbl_name"
                      placeholder="Select"
                      :options="$store.state.master.language"
                      :reduce="(label) => label.lbl_id"
                      multiple
                      :close-on-select="false"
                      v-model="release.language"
                    />
                  </td>

                  <td>
                    <flat-pickr
                      :config="{
                        ...$flatPickrConfig,
                      }"
                      v-model="release.plannedDate"
                      placeholder="Planned Date"
                      class="form-control"
                    />
                  </td>
                  <td>
                    <flat-pickr
                      :config="{
                        ...$flatPickrConfig,
                      }"
                      v-model="release.date"
                      placeholder="Actual Date"
                      class="form-control"
                    />
                  </td>
                  <td>
                    <feather-icon
                      class="remove-row"
                      icon="MinusCircleIcon"
                      size="25"
                      @click="removeRelease(release_index)"
                    />
                  </td>
                </tr>
                <tr>
                  <td colspan="6">
                    <feather-icon
                      class="add-row"
                      icon="PlusCircleIcon"
                      size="25"
                      @click="addRelease"
                    />
                  </td>
                </tr>
              </table>
            </b-card>
          </b-tab>
          <b-tab title="Stills">
            <b-card border-variant="primary">
              <b-card-text
                ><all-files-upload
                  v-model="form.movie_information.movie_files" /></b-card-text
            ></b-card>
          </b-tab>
          <b-tab title="Synopsis">
            <b-card border-variant="primary" class="bg-color1">
              <b-card-text>
                <div>
                  <b-row
                    v-for="(synopsis, synopsis_index) in form.movie_synopsis"
                    :key="'movie_synopsis_' + synopsis_index"
                    class="form-rows"
                  >
                    <b-col cols="11">
                      <b-row>
                        <b-col cols="6">
                          <b-form-group
                            label="Language"
                            :label-for="
                              'mc-synopsis-languages-' + synopsis_index
                            "
                          >
                            <v-select
                              :id="'mc-synopsis-languages-' + synopsis_index"
                              label="lbl_name"
                              placeholder="Select"
                              :options="$store.state.master.language"
                              :reduce="(label) => label.lbl_id"
                              v-model="synopsis.language"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col>
                          <b-form-group
                            label="Title"
                            :label-for="'synopsis-title-' + synopsis_index"
                          >
                            <b-form-input
                              :id="'synopsis-title-' + synopsis_index"
                              placeholder="title"
                              v-model="synopsis.title"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12">
                          <b-form-group
                            label="Press Quotes "
                            :label-for="
                              'synopsis-press-quotes-' + synopsis_index
                            "
                          >
                            <b-form-textarea
                              :id="'synopsis-press-quotes-' + synopsis_index"
                              size="sm"
                              placeholder="Press Quotes"
                              v-model="synopsis.press_quotes"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="12" md="6" lg="6">
                          <b-form-group
                            :label="
                              'Short Synopsis' +
                              ' (' +
                              (synopsis.synopsis_short
                                ? synopsis.synopsis_short.length
                                : 0) +
                              '/' +
                              10000 +
                              ')'
                            "
                            :label-for="'synopsis-short-' + synopsis_index"
                          >
                            <!-- {{
                              synopsis.synopsis_short
                                ? `${
                                    10000 - synopsis.synopsis_short.length
                                  } characters left`
                                : ""
                            }} -->

                            <b-form-textarea
                              :id="'synopsis-short-' + synopsis_index"
                              size="sm"
                              placeholder="Short Synopsis"
                              v-model="synopsis.synopsis_short"
                              maxlength="10000"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="6" lg="6">
                          <b-form-group
                            :label="
                              'Long Synopsis' +
                              ' (' +
                              (synopsis.synopsis_long
                                ? synopsis.synopsis_long.length
                                : 0) +
                              '/' +
                              65000 +
                              ')'
                            "
                            :label-for="'synopsis-long-' + synopsis_index"
                          >
                            <!-- {{
                              synopsis.synopsis_long
                                ? `${
                                    10000 - synopsis.synopsis_long.length
                                  } characters left`
                                : ""
                            }} -->
                            <b-form-textarea
                              :id="'synopsis-long-' + synopsis_index"
                              size="sm"
                              placeholder="Long Synopsis"
                              v-model="synopsis.synopsis_long"
                              maxlength="65000"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col cols="1">
                      <feather-icon
                        class="remove-row mt-2"
                        icon="MinusCircleIcon"
                        size="25"
                        @click="removeSynopsis(synopsis_index)"
                      />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12">
                      <feather-icon
                        class="add-row"
                        icon="PlusCircleIcon"
                        size="25"
                        @click="addSynopsis"
                      />
                    </b-col>
                  </b-row></div
              ></b-card-text>
            </b-card>
          </b-tab>
          <b-tab title="Materials (Digital)">
            <b-card class="border border-primary" style="overflow-x: scroll">
              <b-table
                :items="form.material_digital_arry"
                :fields="materialDigitalFormFields"
                responsive="sm"
                small
                bordered
              >
                <template #cell(show_details)="row">
                  <b-form-checkbox
                    v-model="row.detailsShowing"
                    @change="row.toggleDetails"
                  >
                  </b-form-checkbox>
                </template>

                <template #cell(remove)="row">
                  <div class="d-flex justify-content-center align-items-center">
                    <feather-icon
                      @click="removeMaterial(row.index, 1)"
                      class="remove-row"
                      icon="MinusCircleIcon"
                      size="25"
                    />
                  </div>
                </template>

                <template #cell(Material)="row">
                  <v-select
                    v-model="row.item.material"
                    :id="'material'"
                    label="lbl_name"
                    placeholder="Select"
                    style="width: 200px"
                    :options="$store.state.master.material_digital"
                    :reduce="(label) => label.lbl_id"
                    class="fix-select"
                  >
                    <template #no-options="data">
                      <b-button
                        @click="() => addLabel('material_digital', data.search)"
                        >Add New Material</b-button
                      >
                    </template>
                  </v-select>
                </template>

                <template #cell(Language)="row">
                  <v-select
                    v-model="row.item.material_language_version"
                    :id="'Material-Language-Version'"
                    label="lbl_name"
                    placeholder="Select"
                    style="width: 200px"
                    :options="$store.state.master.language"
                    :reduce="(label) => label.lbl_id"
                    class="fix-select"
                  />
                </template>

                <template #cell(Subtitles)="row">
                  <v-select
                    mutiple
                    v-model="row.item.material_subtitles"
                    :id="'Subtitles'"
                    label="lbl_name"
                    placeholder="Select"
                    multiple
                    style="width: 200px"
                    :options="$store.state.master.language"
                    :reduce="(label) => label.lbl_id"
                    class="fix-select"
                  />
                </template>

                <template #cell(Language_Booking_Code)="row">
                  <v-select
                    v-model="row.item.language_booking_code"
                    :id="'Language-Booking-Code'"
                    label="lbl_name"
                    placeholder="Select"
                    style="width: 200px"
                    :options="$store.state.master.language_booking_code"
                    :reduce="(label) => label.lbl_id"
                    class="fix-select"
                  />
                </template>

                <template #cell(Screen_Definition)="row">
                  <v-select
                    v-model="row.item.screen_definition"
                    :id="'Screen-Definition'"
                    label="lbl_name"
                    placeholder="Select"
                    style="width: 200px"
                    class="fix-select"
                    :options="$store.state.master.screen_definition"
                    :reduce="(label) => label.lbl_id"
                  />
                </template>

                <template #cell(Aspect_Ratio)="row">
                  <v-select
                    v-model="row.item.aspect_ratio"
                    :id="'Aspect-Ratio'"
                    label="lbl_name"
                    placeholder="Select"
                    style="width: 200px"
                    class="fix-select"
                    :options="$store.state.master.aspect_ratio"
                    :reduce="(label) => label.lbl_id"
                  />
                </template>

                <template #cell(Depot)="row">
                  <v-select
                    v-model="row.item.depot_portal"
                    :id="'Depot-Portal'"
                    label="lbl_name"
                    placeholder="Select"
                    style="width: 200px"
                    class="fix-select"
                    :options="$store.state.master.depot_portal"
                    :reduce="(label) => label.lbl_id"
                  />
                </template>

                <template #cell(File_Code_CPL)="row">
                  <b-form-input
                    style="width: 200px"
                    v-model="row.item.file_code_cpl"
                    id="File-Code-CPL"
                    placeholder="File Code CPL"
                  />
                </template>

                <template #cell(Weblink)="row">
                  <b-form-input
                    style="width: 200px"
                    v-model="row.item.assests_weblink"
                    id="Asset-Weblink"
                    placeholder="Asset/Weblink"
                  />
                </template>

                <template #cell(File_Upload)="row">
                  <div style="width: 300px">
                    <files-container v-model="row.item.files" />
                  </div>
                </template>

                <template #cell(Description)="row">
                  <b-form-textarea
                    style="width: 300px"
                    v-model="row.item.material_description"
                    id="Description"
                    placeholder="Description"
                  />
                </template>

                <template #row-details="row">
                  <div class="p-1 px-2 bg-color1">
                    <b-row class="form-rows p-1">
                      <b-col>
                        <b-form-group
                          label="Sound Format"
                          :label-for="'Sound-Format'"
                        >
                          <v-select
                            v-model="row.item.sound_format"
                            :id="'Sound-Format'"
                            label="lbl_name"
                            placeholder="Select"
                            :options="$store.state.master.sound_format"
                            :reduce="(label) => label.lbl_id"
                            class="fix-select"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group label="DCP" :label-for="'DCP'">
                          <v-select
                            v-model="row.item.material_dcp"
                            :id="'DCP'"
                            label="lbl_name"
                            placeholder="Select"
                            :options="dcpOptions"
                            :reduce="(label) => label.lbl_id"
                            class="fix-select"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col>
                        <b-form-group label="DKDM" :label-for="'DKDM'">
                          <v-select
                            v-model="row.item.material_dkdm"
                            :id="'DKDM'"
                            label="lbl_name"
                            placeholder="Select"
                            :options="dkdmOptions"
                            :reduce="(label) => label.lbl_id"
                            class="fix-select"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group label="KDM" :label-for="'KDM'">
                          <v-select
                            v-model="row.item.material_kdm"
                            :id="'KDM'"
                            label="lbl_name"
                            placeholder="Select"
                            :options="kdmOptions"
                            :reduce="(label) => label.lbl_id"
                            class="fix-select"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group
                          label="KDM Depot/Portal"
                          :label-for="'KDM-Depot-Portal'"
                        >
                          <v-select
                            v-model="row.item.kdm_depot_portal"
                            :id="'KDM-Depot-Portal'"
                            label="lbl_name"
                            placeholder="Select"
                            :options="$store.state.master.depot_portal"
                            :reduce="(label) => label.lbl_id"
                            class="fix-select"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group label="KDM Code" label-for="KDM-Code">
                          <b-form-input
                            v-model="row.item.kdm_code"
                            id="KDM-Code"
                            placeholder="KDM Code"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group
                          label="File Code UUID"
                          label-for="File-Code-UUID"
                        >
                          <b-form-input
                            v-model="row.item.file_code_uuid"
                            id="File-Code-UUID"
                            placeholder="File Code UUID"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                </template>
              </b-table>
              <feather-icon
                class="add-row"
                icon="PlusCircleIcon"
                size="25"
                @click="addMovieMaterial('1')"
              />
            </b-card>
          </b-tab>
          <b-tab title="Materials (Physical)">
            <b-card class="border border-primary" style="overflow-x: scroll">
              <b-table
                :items="form.material_printed_arry"
                :fields="materialPhysicalFormFields"
                responsive="sm"
                small
                bordered
              >
                <template #cell(remove)="row">
                  <div class="d-flex justify-content-center align-items-center">
                    <feather-icon
                      @click="removeMaterial(row.index, 2)"
                      class="remove-row"
                      icon="MinusCircleIcon"
                      size="25"
                    />
                  </div>
                </template>

                <template #cell(Material)="row">
                  <v-select
                    v-model="row.item.material"
                    :id="'material'"
                    label="lbl_name"
                    style="width: 200px"
                    placeholder="Select"
                    :options="$store.state.master.material_physical"
                    :reduce="(label) => label.lbl_id"
                    class="fix-select"
                  >
                    <template #no-options="data">
                      <b-button
                        @click="
                          () => addLabel('material_physical', data.search)
                        "
                        >Add New Material</b-button
                      >
                    </template>
                  </v-select>
                </template>

                <template #cell(Format)="row">
                  <b-form-input
                    v-model="row.item.sound_format"
                    id="Units-Ordered"
                    placeholder="Format"
                    style="width: 200px"
                  />
                </template>

                <template #cell(Language)="row">
                  <v-select
                    v-model="row.item.language"
                    :id="'Material-Language-Version'"
                    label="lbl_name"
                    placeholder="Select"
                    :options="$store.state.master.language"
                    :reduce="(label) => label.lbl_id"
                    style="width: 200px"
                    class="fix-select"
                  />
                </template>

                <template #cell(Language_Booking_Code)="row">
                  <v-select
                    v-model="row.item.language_booking_code"
                    :id="'Language-Booking-Code'"
                    label="lbl_name"
                    placeholder="Select"
                    :options="$store.state.master.language_booking_code"
                    :reduce="(label) => label.lbl_id"
                    style="width: 200px"
                    class="fix-select"
                  />
                </template>

                <template #cell(Units_Ordered)="row">
                  <b-form-input
                    v-model="row.item.units_ordered"
                    id="Units-Ordered"
                    placeholder="Units Ordered"
                    style="width: 200px"
                  />
                </template>

                <template #cell(Units_Recieved)="row">
                  <b-form-input
                    v-model="row.item.units_received"
                    id="Units-Received"
                    placeholder="Units Received"
                    style="width: 200px"
                  />
                </template>

                <template #cell(Units_In_Stock)="row">
                  <b-form-input
                    v-model="row.item.units_in_stock"
                    id="Units-in-stock"
                    placeholder="Units in Stock"
                    style="width: 200px"
                  />
                </template>

                <template #cell(Depot)="row">
                  <v-select
                    v-model="row.item.depot_portal"
                    :id="'Depot-Portal'"
                    label="lbl_name"
                    placeholder="Select"
                    :options="$store.state.master.depot_portal"
                    :reduce="(label) => label.lbl_id"
                    style="width: 200px"
                    class="fix-select"
                  />
                </template>

                <template #cell(File_Upload)="row">
                  <div style="width: 300px">
                    <files-container v-model="row.item.files" />
                  </div>
                </template>

                <template #cell(Description)="row">
                  <b-form-textarea
                    style="width: 300px"
                    v-model="row.item.material_description"
                    id="Description"
                    placeholder="Description"
                  />
                </template>
              </b-table>
              <feather-icon
                class="add-row"
                icon="PlusCircleIcon"
                size="25"
                @click="addMovieMaterial('2')"
              />
            </b-card>
          </b-tab>
          <b-tab title="Distributors">
            <b-card border-variant="primary" class="bg-color1">
              <b-card-text>
                <div>
                  <b-row
                    v-for="(dis, dis_index) in form.movie_distributors"
                    :key="'dis-' + dis_index"
                    class="form-rows"
                  >
                    <b-col cols="11">
                      <b-row>
                        <b-col cols="11" sm="11" md="5" lg="5">
                          <b-form-group
                            label="Distributor"
                            :label-for="'mc-distributor-name-' + dis_index"
                          >
                            <v-select
                              :id="'mc-distributor-country-' + dis_index"
                              label="lbl_name"
                              placeholder="Select"
                              :options="$store.state.master.distributor"
                              :reduce="(label) => label.lbl_id"
                              v-model="dis.name"
                            >
                              <template #no-options="data">
                                <b-button
                                  @click="
                                    () => addLabel('distributor', data.search)
                                  "
                                >
                                  Add New Distributor
                                </b-button>
                              </template>
                            </v-select>
                          </b-form-group>
                        </b-col>
                        <b-col cols="11" sm="11" md="5" lg="5">
                          <b-form-group
                            label="Country"
                            :label-for="'mc-dis-country-' + dis_index"
                          >
                            <v-select
                              :id="'mc-dis-country-' + dis_index"
                              label="lbl_name"
                              placeholder="Select"
                              :options="$store.state.master.country"
                              :reduce="(label) => label.lbl_id"
                              v-model="dis.place"
                            >
                              <template #no-options="data">
                                <b-button
                                  @click="
                                    () => addLabel('country', data.search)
                                  "
                                >
                                  Add New Country
                                </b-button>
                              </template>
                            </v-select>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col cols="1"
                      ><feather-icon
                        class="remove-row mt-2"
                        icon="MinusCircleIcon"
                        size="25"
                        @click="removeDistributor(dis_index)"
                    /></b-col>
                  </b-row>
                </div>
                <b-row>
                  <b-col cols="12">
                    <feather-icon
                      class="add-row"
                      icon="PlusCircleIcon"
                      size="25"
                      @click="addDistributor"
                    />
                  </b-col> </b-row
              ></b-card-text>
            </b-card>
          </b-tab>
          <b-tab title="Products">
            <b-card border-variant="primary" class="bg-color1">
              <b-card-text>
                <div>
                  <b-row
                    v-for="(product, product_index) in form.movie_product"
                    :key="'movie_product_' + product_index"
                    class="form-rows"
                  >
                    <b-col cols="11">
                      <b-row>
                        <b-col cols="12" md="4">
                          <b-form-group
                            label="Product Title"
                            :label-for="'product-title-' + product_index"
                          >
                            <b-form-input
                              :id="'product-title-' + product_index"
                              v-model="product.title"
                              placeholder="  Product Title"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4">
                          <b-form-group
                            label="Media Type"
                            :label-for="
                              'mc-product-media-type-' + product_index
                            "
                          >
                            <v-select
                              :id="'mc-product-media-type-' + product_index"
                              label="lbl_name"
                              placeholder="Select"
                              :options="$store.state.master.media_type"
                              :reduce="(label) => label.lbl_id"
                              :close-on-select="false"
                              v-model="product.media_type"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4">
                          <b-form-group
                            label="Product Status"
                            :label-for="'mc-product-status-' + product_index"
                          >
                            <b-form-select
                              :id="'mc-product-status-' + product_index"
                              v-model="product.pstatus"
                              placeholder="Select"
                            >
                              <b-form-select-option value="1"
                                >Active</b-form-select-option
                              >
                              <b-form-select-option value="0"
                                >Inactive</b-form-select-option
                              >
                            </b-form-select>
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4">
                          <b-form-group
                            label="Catalog Number"
                            :label-for="
                              'product-catalog-number-' + product_index
                            "
                          >
                            <b-form-input
                              :id="'product-catalog-number-' + product_index"
                              v-model="product.catalog_number"
                              placeholder="  Catalog Number"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4">
                          <b-form-group
                            label="EAN"
                            :label-for="'product-ean-' + product_index"
                          >
                            <b-form-input
                              :id="'product-ean-' + product_index"
                              v-model="product.ean"
                              placeholder="  EAN"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4">
                          <b-form-group
                            label="Language Tracks"
                            :label-for="'product-lang-tracks-' + product_index"
                          >
                            <v-select
                              :id="'product-lang-tracks-' + product_index"
                              label="lbl_name"
                              placeholder="Select"
                              :options="$store.state.master.language"
                              :reduce="(label) => label.lbl_id"
                              v-model="product.language_tracks"
                              multiple
                              :close-on-select="false"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4">
                          <b-form-group
                            label="Subtitles"
                            :label-for="'product-lang-tracks-' + product_index"
                          >
                            <v-select
                              :id="'product-lang-tracks-' + product_index"
                              label="lbl_name"
                              placeholder="Select"
                              :options="$store.state.master.language"
                              :reduce="(label) => label.lbl_id"
                              v-model="product.subtitle"
                              multiple
                              :close-on-select="false"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4">
                          <b-form-group
                            label="Running Time (Min)"
                            :label-for="'product-running-time-' + product_index"
                          >
                            <b-form-input
                              :id="'product-running-time-' + product_index"
                              v-model="product.runtime"
                              placeholder="  Running Time (Min)"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4">
                          <b-form-group
                            label="Pricing Code"
                            :label-for="'product-pricing-code-' + product_index"
                          >
                            <v-select
                              :id="'product-pricing-code-' + product_index"
                              label="lbl_name"
                              placeholder="Select"
                              :options="$store.state.master.pricing_code"
                              :reduce="(label) => label.lbl_id"
                              v-model="product.price_code"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4">
                          <b-form-group
                            label="Aspect Ratio"
                            :label-for="'product-aspect-ratio-' + product_index"
                          >
                            <v-select
                              :id="'product-aspect-ratio-' + product_index"
                              v-model="product.aspect_ratio"
                              label="lbl_name"
                              placeholder="Select"
                              :options="$store.state.master.aspect_ratio"
                              :reduce="(label) => label.lbl_id"
                              multiple
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4">
                          <b-form-group
                            label="Number of Discs"
                            :label-for="'product-number-discs-' + product_index"
                          >
                            <b-form-input
                              :id="'product-running-time-' + product_index"
                              v-model="product.number_of_disc"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4">
                          <b-form-group
                            label="Label"
                            :label-for="'product-label-' + product_index"
                          >
                            <v-select
                              :id="'product-label-' + product_index"
                              label="lbl_name"
                              placeholder=" Select"
                              :options="$store.state.master.label"
                              :reduce="(label) => label.lbl_id"
                              v-model="product.label"
                              multiple
                              :close-on-select="false"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4">
                          <b-form-group
                            label="Product Purchased From"
                            :label-for="'product-purchase-' + product_index"
                          >
                            <b-form-input
                              :id="'product-purchase-' + product_index"
                              v-model="product.purchased_from"
                              placeholder="  Purchased"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4">
                          <b-form-group
                            label="Authoring Studio"
                            :label-for="'product-studio-' + product_index"
                          >
                            <b-form-input
                              :id="'product-studio-' + product_index"
                              v-model="product.product_studio"
                              placeholder="  Authoring Studio"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4">
                          <b-form-group
                            label="Product Duplicator"
                            :label-for="'product-duplicator-' + product_index"
                          >
                            <b-form-input
                              :id="'product-duplicator-' + product_index"
                              v-model="product.product_duplicator"
                              placeholder="  Duplicator"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4">
                          <b-form-group
                            label="Key Marketing Points"
                            :label-for="'product-marketing-' + product_index"
                          >
                            <b-form-input
                              :id="'product-marketing-' + product_index"
                              v-model="product.marketing_points"
                              placeholder="  Key Marketing Price"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4">
                          <b-form-group
                            label="Rating"
                            :label-for="'product-rating-' + product_index"
                          >
                            <v-select
                              :id="'product-rating-' + product_index"
                              label="lbl_name"
                              placeholder=" Select"
                              :options="$store.state.master.age"
                              :reduce="(label) => label.lbl_id"
                              v-model="product.rating"
                              multiple
                              :close-on-select="false"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4">
                          <b-form-group
                            label="SUISA"
                            :label-for="'mc-product-suisa-' + product_index"
                          >
                            <b-form-select
                              :id="'mc-product-suisa-' + product_index"
                              v-model="product.suisa"
                              placeholder="Select"
                            >
                              <b-form-select-option value="1"
                                >Yes</b-form-select-option
                              >
                              <b-form-select-option value="0"
                                >No</b-form-select-option
                              >
                            </b-form-select>
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4">
                          <b-form-group
                            label="Genre"
                            :label-for="'product-genre-' + product_index"
                          >
                            <v-select
                              :id="'product-genre-' + product_index"
                              label="lbl_name"
                              placeholder="Select"
                              :options="$store.state.master.genre"
                              :reduce="(label) => label.lbl_id"
                              v-model="product.genre"
                              multiple
                              :close-on-select="false"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4">
                          <label
                            :for="'product-description-' + product_index"
                            class="text-nowrap"
                            >Product Description</label
                          >
                          <b-form-textarea
                            :id="'product-description-' + product_index"
                            size="sm"
                            placeholder="Product Description"
                            v-model="product.product_description"
                          />
                        </b-col>
                        <b-col cols="12">
                          <label class="text-nowrap">Artwork</label>
                          <files-container v-model="product.product_art" />
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col cols="1">
                      <feather-icon
                        class="remove-row mt-2"
                        icon="MinusCircleIcon"
                        size="25"
                        @click="removeProduct(product_index)"
                      />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12">
                      <feather-icon
                        class="add-row"
                        icon="PlusCircleIcon"
                        size="25"
                        @click="addProduct"
                      />
                    </b-col>
                  </b-row></div
              ></b-card-text>
            </b-card>
          </b-tab>
        </b-tabs>
      </div>
    </div>

    <div class="pb-5">
      <div class="d-flex justify-content-center">
        <button
          v-if="activeTabIndex > 0"
          class="btn btn-outline-primary"
          @click="
            () => {
              activeTabIndex -= 1;
            }
          "
        >
          Previous
        </button>
        <div>
          <button
            v-if="activeTabIndex > 0"
            @click="
              () => {
                showModal('Are you sure want to submit?', 'save').then(
                  (value) => {
                    if (value) saveMovie();
                  }
                );
              }
            "
            class="mx-1 btn btn-primary"
          >
            Submit
          </button>

          <button
            v-if="!movie_id && !isNaN(movie_id)"
            @click="saveMovieAsDraft"
            class="mr-1 btn btn-info"
          >
            Save As Draft
          </button>

          <!-- <b-button variant="warning" @click="resetForm"> Reset </b-button> -->
          <button
            class="btn btn-outline-primary"
            @click="activeTabIndex += 1"
            v-if="activeTabIndex < 9"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BCardText,
  BFormDatepicker,
  BFormTextarea,
  BFormSelect,
  BFormSelectOption,
  BImg,
  BBadge,
  BTabs,
  BTab,
  BAlert,
  BTableSimple,
  BTable,
} from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import Treeselect from "@riophae/vue-treeselect";
import Ripple from "vue-ripple-directive";
import * as MovieServices from "@/apiServices/MovieServices";
import vSelect from "vue-select";
import { labels } from "@/utils/constants";
import {
  convertToIntArr,
  convertToStrArr,
  getTrueOrFalse,
} from "@/utils/helpers";
import flatPickr from "vue-flatpickr-component";
import FeatherIcon from "@core/components/feather-icon/FeatherIcon.vue";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import { addData } from "@/apiServices/MastersServices";
import FilesContainer from "@/views/components/FilesUpload/FilesContainer.vue";
import AllFilesUpload from "@/views/components/FilesUpload/AllFilesUpload.vue";
import MovieDetails from "@/views/pages/Movie/MovieDetails.vue";
import showModal from "../../components/ModalConfirmation";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BCard,
    BCardText,
    BFormTextarea,
    AppCollapse,
    AppCollapseItem,
    BFormDatepicker,
    vSelect,
    Treeselect,
    flatPickr,
    FeatherIcon,
    BFormSelect,
    BFormSelectOption,
    BImg,
    BBadge,
    FilesContainer,
    AllFilesUpload,
    MovieDetails,
    BTabs,
    BTab,
    BAlert,
    BTableSimple,
    BTable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      activeTabIndex: 1,
      newMovieUniqueId: null,
      movie_id: null,
      materialDigitalFormFields: [
        "show_details",
        "Remove",
        "Material",
        "Language",
        "Subtitles",
        "Language_Booking_Code",
        "Screen_Definition",
        "Aspect_Ratio",
        "Depot",
        "File_Code_CPL",
        "Weblink",
        "File_Upload",
        "Description",
      ],
      materialPhysicalFormFields: [
        "Remove",
        "Material",
        "Format",
        "Language",
        "Language_Booking_Code",
        "Units_Ordered",
        "Units_Recieved",
        "Units_In_Stock",
        "Language_Booking_Code",
        "Depot",
        "File_Upload",
        "Description",
      ],
      dcpOptions: [
        {
          lbl_name: "Encrypted",
          lbl_id: "encrypted",
        },
        {
          lbl_name: "Unencrypted",
          lbl_id: "unencrypted",
        },
      ],
      dkdmOptions: [
        {
          lbl_name: "Yes",
          lbl_id: "Y",
        },
        {
          lbl_name: "No",
          lbl_id: "N",
        },
      ],
      kdmOptions: [
        {
          lbl_name: "Yes",
          lbl_id: "Y",
        },
        {
          lbl_name: "No",
          lbl_id: "N",
        },
      ],
      form: {
        draft: false,
        movie_information: {
          website_visible: false,
          title: "",
          international_title: "",
          type: [],
          year: "",
          original_language: [],
          director: [],
          cast: [],
          genres: [],
          country: [],
          duration: "",
          age: [],
          suisan_number: "",
          image_format: [],
          sound_format: [],
          isan_number: "",
          unique: "",
          weblink: "",
          trailer_link: "",
          avaliable_languages: [],
          avaliable_subTitle: [],
          movie_files: [],
          // original_art_work: [],
          // movie_stills: [],
          // music_que_sheet: [],
          // subtitle_files: [],
          // audio_files: [],
          // various_files: [],
          // social_media: [],
          // press_kit: [],
          status: null,
        },
        additional_langs: [],
        additional_langs_info: [],
        /*
        {
          language_id: '',
          langauge_name: '',
          title: '',
          trailer_link: '',
          artwork: ';,
          pictures: '',
          trailer: '',
          social_media: '',
          audio_files: '',
          subtitle_files: ''
          school_dossier: ''
        }
        */
        movie_release: [
          {
            date: "",
            plannedDate: null,
            language: [],
            right: [],
            territory: [],
          },
        ],
        movie_synopsis: [
          {
            language: "",
            title: "",
            synopsis_short: "",
            synopsis_long: "",
            press_quotes: "",
          },
        ],
        movie_product: [
          {
            title: "",
            media_type: [],
            pstatus: "",
            catalog_number: "",
            ean: "",
            language_tracks: [],
            subtitle: [],
            runtime: "",
            price_code: "",
            aspect_ratio: [],
            number_of_disc: "",
            label: [],
            purchased_from: "",
            product_studio: "",
            product_duplicator: "",
            marketing_points: "",
            rating: [],
            suisa: "",
            genre: [],
            product_description: "",
          },
        ],
        movie_distributors: [
          {
            name: "",
            country: "",
          },
        ],
        material_digital_arry: [
          {
            material_type: 1, // 1 = digital ,2 = printed
            material: null,
            material_language_version: null,

            language_booking_code: null,
            material_description: null,
            screen_definition: null,
            aspect_ratio: null,
            sound_format: null,
            units_ordered: null,
            units_received: null,
            units_in_stock: null,
            depot_portal: null,
            file_code_cpl: null,
            file_code_uuid: null,
            kdm_depot_portal: null,
            kdm_code: null,
            assests_weblink: null,
            material_subtitles: null,
            material_dcp: null,
            material_dkdm: null,
            material_kdm: null,
            files: [],
          },
        ],

        material_printed_arry: [
          {
            material_type: 2, // 1 = digital ,2 = printed
            material: null,
            material_language_version: null,
            language_booking_code: null,
            material_description: null,
            screen_definition: null,
            aspect_ratio: null,
            sound_format: null,
            units_ordered: null,
            units_received: null,
            units_in_stock: null,
            depot_portal: null,
            file_code_cpl: null,
            file_code_uuid: null,
            kdm_depot_portal: null,
            kdm_code: null,
            assests_weblink: null,
          },
        ],
      },
      filtersHidden: true,
      searchReleaseDateTerritory: [],
      searchReleaseDateRight: [],
    };
  },
  computed: {
    files_url() {
      return process.env.VUE_APP_FILESURL;
    },
  },
  methods: {
    showModal,
    onSearchRelease() {
      if (
        this.searchReleaseDateRight.length &&
        this.searchReleaseDateTerritory.length
      ) {
        for (let item of this.searchReleaseDateRight) {
          for (let itemT of this.searchReleaseDateTerritory) {
            for (let [indexRight, right] of this.form.movie_release.entries()) {
              let rightArray = right.right;
              let TerrArray = right.territory;

              let checkExist = rightArray.indexOf(item);
              let checkExistTerr = TerrArray.indexOf(itemT);

              if (checkExist != -1 && checkExistTerr != -1) {
                let obj = right;
                this.form.movie_release.splice(indexRight, 1);
                this.form.movie_release.unshift(obj);
                // let rev = [...this.form.movie_release].reverse();
                // this.form.movie_release = rev;
              }
            }
          }
        }
      } else if (this.searchReleaseDateRight.length) {
        for (let item of this.searchReleaseDateRight) {
          for (let [indexRight, right] of this.form.movie_release.entries()) {
            let rightArray = right.right;

            let checkExist = rightArray.indexOf(item);

            if (checkExist != -1) {
              let obj = right;
              this.form.movie_release.splice(indexRight, 1);
              this.form.movie_release.unshift(obj);
              // let rev = [...this.form.movie_release].reverse();
              // this.form.movie_release = rev;
            }
          }
        }
      } else if (this.searchReleaseDateTerritory.length) {
        for (let item of this.searchReleaseDateTerritory) {
          for (let [indexRight, right] of this.form.movie_release.entries()) {
            let TerArray = right.territory;

            let checkExist = TerArray.indexOf(item);

            if (checkExist != -1) {
              let obj = right;
              this.form.movie_release.splice(indexRight, 1);
              this.form.movie_release.unshift(obj);
              // let rev = [...this.form.movie_release].reverse();
              // this.form.movie_release = rev;
            }
          }
        }
      } else {
        console.log("nothig selected");
      }
    },
    hideShowFilter() {
      this.filtersHidden = !this.filtersHidden;
    },
    addMovieMaterial(type) {
      let obj = {
        material_type: type, // 1 = digital ,2 = printed
        material: null,
        material_language_version: null,
        language_booking_code: null,
        material_description: null,
        screen_definition: null,
        aspect_ratio: null,
        sound_format: null,
        units_ordered: null,
        units_received: null,
        units_in_stock: null,
        depot_portal: null,
        file_code_cpl: null,
        file_code_uuid: null,
        kdm_depot_portal: null,
        kdm_code: null,
        assests_weblink: null,
        files: [],
      };
      console.log(type, "type");
      if (type == "1") {
        this.form.material_digital_arry.push(obj);
      } else {
        this.form.material_printed_arry.push(obj);
      }

      console.log(this.form.material_digital_arry);
    },
    async addLabel(cat, text) {
      const response = await addData(cat, { lbl_name: text });
      if (!response.data.data.lbl_id) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: response.data.message || "Cast Not added!",
            icon: "EditIcon",
            variant: "failure",
          },
        });
        return;
      }

      this.$toast({
        component: ToastificationContentVue,
        props: {
          title: response.data.message || "Success",
          icon: "EditIcon",
          variant: "success",
        },
      });

      this.$store.dispatch("master/setLabelMaster", { key: cat });
    },
    onAdditionalLangInput(input) {
      const additional_langs_info_arr = [];

      for (const lbl_id of input) {
        let obj = undefined;

        for (const lang_info of this.form.additional_langs_info) {
          if (lang_info.language_id === lbl_id) {
            obj = lang_info;
          }
        }

        if (!obj) {
          const langauge_lbl = this.$store.state.master.language.find(
            (item) => {
              if (item.lbl_id === lbl_id) return true;
              return false;
            }
          );

          obj = {
            language_id: lbl_id,
            langauge_name: langauge_lbl ? langauge_lbl.lbl_name : "",
            title: "",
            trailer_link: "",
            files: [],
          };
        }

        additional_langs_info_arr.push(obj);
      }

      this.form.additional_langs_info = additional_langs_info_arr;
    },

    addRelease() {
      this.form.movie_release.push({
        date: "",
        plannedDate: null,
        language: [],
        right: [],
        territory: [],
      });
    },

    removeRelease(index) {
      this.form.movie_release.splice(index, 1);
    },

    addSynopsis() {
      this.form.movie_synopsis.push({
        language: "",
        title: "",
        synopsis_short: "",
        synopsis_long: "",
        press_quotes: "",
      });
    },

    removeSynopsis(index) {
      this.form.movie_synopsis.splice(index, 1);
    },

    addProduct() {
      this.form.movie_product.push({
        title: "",
        media_type: [],
        pstatus: "",
        catalog_number: "",
        ean: "",
        language_tracks: [],
        subtitle: [],
        runtime: "",
        price_code: "",
        aspect_ratio: [],
        number_of_disc: "",
        label: [],
        purchased_from: "",
        product_studio: "",
        product_duplicator: "",
        marketing_points: "",
        rating: [],
        suisa: "",
        genre: [],
        product_description: "",
      });
    },

    addDistributor() {
      this.form.movie_distributors.push({
        name: "",
        country: "",
      });
    },

    removeDistributor(index) {
      this.form.movie_distributors.splice(index, 1);
    },

    removeMaterial(index, type) {
      if (type == 1) {
        this.form.material_digital_arry.splice(index, 1);
      } else {
        this.form.material_printed_arry.splice(index, 1);
      }
    },

    removeProduct(index) {
      this.form.movie_product.splice(index, 1);
    },

    async GetMovieUniqueId() {
      try {
        const response = await MovieServices.getNewMovieUnique();
        if (response.data.status) {
          if (response.data.data && response.data.data.unique) {
            this.newMovieUniqueId = parseInt(response.data.data.unique) + 1;
          }
        }
      } catch (err) {
        console.log("Error in getting  ", err);
      }
    },

    async getMovieDetails(movie_id) {
      try {
        const response = await MovieServices.getSingleMovie(movie_id);

        if (response.data.status) {
          const {
            movie_information,
            // movie_assets,
            additional_langs,
            additional_langs_info,
            movie_distributors,
            movie_product,
            movie_release,
            movie_synopsis,
            movie_material_digital,
            movie_material_printed,
          } = response.data.data;

          this.form.movie_information = {
            ...movie_information,
            website_visible: getTrueOrFalse(movie_information.website_visible),
            type: convertToIntArr(movie_information.type),
            original_language: convertToIntArr(
              movie_information.original_language
            ),
            director: convertToIntArr(movie_information.director),
            cast: convertToIntArr(movie_information.cast),
            genres: convertToIntArr(movie_information.genres),
            country: convertToIntArr(movie_information.country),
            age: convertToIntArr(movie_information.age),
            image_format: convertToIntArr(movie_information.image_format),
            sound_format: convertToIntArr(movie_information.sound_format),
            avaliable_languages: convertToIntArr(
              movie_information.avaliable_languages
            ),
            avaliable_subTitle: convertToIntArr(
              movie_information.avaliable_subTitle
            ),
            movie_files: movie_information.movie_files,
            // original_art_work: convertToStrArr(
            //   movie_information.original_art_work
            // ),
            // movie_stills: convertToStrArr(movie_information.movie_stills),
            // music_que_sheet: convertToStrArr(movie_information.music_que_sheet),
            // subtitle_files: convertToStrArr(movie_information.subtitle_files),
            // audio_files: convertToStrArr(movie_information.audio_files),
            // various_files: convertToStrArr(movie_information.various_files),
            // social_media: convertToStrArr(movie_information.social_media),
            // press_kit: convertToStrArr(movie_information.press_kit),
          };

          if (movie_release && movie_release.length) {
            this.form.movie_release = movie_release.map((release) => {
              return {
                date: release.date,
                plannedDate: release.plannedDate,
                language: convertToIntArr(release.language),
                right: convertToIntArr(release.right),
                territory: convertToIntArr(release.terriorty),
              };
            });
          }

          if (movie_synopsis && movie_synopsis.length) {
            this.form.movie_synopsis = movie_synopsis.map((synopsis) => {
              return {
                ...synopsis,
                language: synopsis.language,
              };
            });
          }

          if (movie_material_digital && movie_material_digital.length) {
            this.form.material_digital_arry = movie_material_digital.map(
              (material) => {
                return {
                  ...material,
                  files: material.files ? JSON.parse(material.files) : [],
                  material_subtitles: convertToIntArr(
                    material.material_subtitles
                  ),
                };
              }
            );
            // this.form.material_digital_arry = movie_material_digital;
          }
          if (movie_material_printed && movie_material_printed.length) {
            this.form.material_printed_arry = movie_material_printed.map(
              (material) => ({
                ...material,
                files: material.files ? JSON.parse(material.files) : [],
              })
            );
          }

          if (movie_product && movie_product.length) {
            this.form.movie_product = movie_product.map((product) => {
              return {
                ...product,
                aspect_ratio: convertToIntArr(product.aspect_ratio),
                label: convertToIntArr(product.label),
                language_tracks: convertToIntArr(product.language_tracks),
                media_type: convertToIntArr(product.media_type),
                rating: convertToIntArr(product.rating),
                subtitle: convertToIntArr(product.subtitle),
                price_code: convertToIntArr(product.price_code),
                genre: convertToIntArr(product.genre),
                product_art: convertToStrArr(product.product_art),
              };
            });
          }

          if (movie_distributors && movie_distributors.length) {
            this.form.movie_distributors = movie_distributors.map((dis) => {
              return {
                ...dis,
                name: dis.name,
                place: dis.place,
              };
            });
          }

          if (additional_langs_info && additional_langs_info.length) {
            this.form.additional_langs_info = additional_langs_info;
          }

          if (additional_langs && additional_langs.length) {
            this.form.additional_langs = additional_langs;
          }
        }
      } catch (err) {
        console.log("Error in getting  ", err);
      }
    },

    saveMovieAsDraft() {
      this.form.draft = true;
      this.$nextTick(() => {
        this.saveMovie();
      });
    },

    async saveMovie() {
      try {
        if (!this.form.movie_information.title) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Please Enter Title",
              icon: "EditIcon",
              variant: "warning",
            },
          });
          return;
        }

        const response = await MovieServices.saveMovie({
          movieID: this.movie_id,
          newMovieUniqueId: this.newMovieUniqueId,
          ...this.form,
        });

        if (response.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Movie Saved Successfully",
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.$router.go(-1);
        } else {
          if (response.data.data && response.data.data.unique) {
            this.newMovieUniqueId = response.data.data.unique;
          }
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Movie Not Saved",
              icon: "EditIcon",
              variant: "failure",
            },
          });
        }
      } catch (err) {
        console.log("Error in saveMovie ", err);
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Server Error",
            icon: "EditIcon",
            variant: "failure",
          },
        });
      }
    },

    resetForm() {
      this.form = {
        draft: false,
        movie_information: {
          title: "",
          international_title: "",
          type: [],
          year: "",
          original_language: [],
          director: [],
          cast: [],
          genres: [],
          country: [],
          duration: "",
          age: [],
          suisan_number: "",
          image_format: [],
          sound_format: [],
          isan_number: "",
          unique: "",
          weblink: "",
          trailer_link: "",
          avaliable_languages: [],
          avaliable_subTitle: [],
          status: null,
        },
        additional_langs: [],
        additional_langs_info: [],
        /*
        {
          language_id: '',
          langauge_name: '',
          title: '',
          trailer_link: '',
          artwork: ';,
          pictures: '',
          trailer: '',
          social_media: '',
          audio_files: '',
          subtitle_files: ''
          school_dossier: ''
        }
        */
        movie_release: [
          {
            date: "",
            plannedDate: null,
            language: [],
            right: [],
            territory: [],
          },
        ],

        movie_synopsis: [
          {
            language: "",
            title: "",
            synopsis_short: "",
            synopsis_long: "",
            press_quotes: "",
          },
        ],
        movie_product: [
          {
            title: "",
            media_type: [],
            pstatus: "",
            catalog_number: "",
            ean: "",
            language_tracks: [],
            subtitle: [],
            runtime: "",
            price_code: "",
            aspect_ratio: [],
            number_of_disc: "",
            label: [],
            purchased_from: "",
            product_studio: "",
            product_duplicator: "",
            marketing_points: "",
            rating: [],
            suisa: "",
            genre: [],
            product_description: "",
          },
        ],
        movie_distributors: [
          {
            name: "",
            country: "",
          },
        ],
      };
    },
  },
  beforeMount() {
    const movie_id = this.$route.params.movie_id;

    this.GetMovieUniqueId();
    if (movie_id && !isNaN(movie_id)) {
      this.getMovieDetails(movie_id);
      this.movie_id = movie_id;
    }

    this.$store.dispatch("master/setLabelMaster", { key: labels.type });
    this.$store.dispatch("master/setLabelMaster", { key: labels.language });
    this.$store.dispatch("master/setLabelMaster", { key: labels.director });
    this.$store.dispatch("master/setLabelMaster", { key: labels.cast });
    this.$store.dispatch("master/setLabelMaster", { key: labels.genre });
    this.$store.dispatch("master/setLabelMaster", { key: labels.country });
    this.$store.dispatch("master/setLabelMaster", { key: labels.age });
    this.$store.dispatch("master/setLabelMaster", { key: labels.image_format });
    this.$store.dispatch("master/setLabelMaster", { key: labels.sound_format });
    this.$store.dispatch("master/setLabelMaster", { key: labels.media_type });
    this.$store.dispatch("master/setLabelMaster", { key: labels.pricing_code });
    this.$store.dispatch("master/setLabelMaster", { key: labels.aspect_ratio });
    this.$store.dispatch("master/setLabelMaster", { key: labels.label });
    this.$store.dispatch("master/setLabelMaster", { key: labels.distributor });
    //
    this.$store.dispatch("master/setLabelMaster", {
      key: labels.material_digital,
    });
    this.$store.dispatch("master/setLabelMaster", {
      key: labels.material_physical,
    });

    this.$store.dispatch("master/setLabelMaster", {
      key: labels.material_language_version,
    });
    this.$store.dispatch("master/setLabelMaster", {
      key: labels.language_booking_code,
    });
    this.$store.dispatch("master/setLabelMaster", {
      key: labels.screen_definition,
    });
    this.$store.dispatch("master/setLabelMaster", {
      key: labels.depot_portal,
    });
    // Tree Data
    this.$store.dispatch("master/setLabelTreeMaster", {
      key: labels.rights_tree,
      master: labels.rights,
      sub_master: labels.sub_right,
    });
    this.$store.dispatch("master/setLabelTreeMaster", {
      key: labels.territory_tree,
      master: labels.territory,
      sub_master: labels.sub_territory,
    });
  },
};
</script>

<style scoped lang="scss">
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  border-radius: 50px;
  border: 2px solid #1f307a;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
th {
  text-align: center;
  background-color: #a5b2e9;
}

tr {
  background-color: #fff;
}
.form-rows {
  // margin-bottom: 30px;
  // padding: 5px;
  border: 1px solid lightcyan;
  background-color: white;
  border-radius: 5px;
}

.add-row {
  display: block;
  margin: auto;
  cursor: pointer;
}

.remove-row {
  display: block;
  /* margin: auto; */
  cursor: pointer;
}

.collapse-group {
  margin-top: 30px;
  margin-bottom: 30px;
}

.bg-color1 {
  padding: 5px;
  background-color: rgba(60, 60, 155, 0.1);
}

.form-rows0 {
  border: 1px solid lightcyan;
  margin-bottom: 15px;
  padding: 5px;
  background-color: white;
}

.form-rows1 {
  border: 1px solid lightgray;
  margin-bottom: 15px;
  padding: 5px;
  background-color: white;
}

.form-rows2 {
  border: 1px solid lightgoldenrodyellow;
  margin-bottom: 15px;
  padding: 5px;
  background-color: white;
}

.form-rows3 {
  border: 1px solid lightskyblue;
  margin-bottom: 15px;
  padding: 5px;
}

.form-rows4 {
  border: 1px solid gray;
  margin-bottom: 15px;
  padding: 5px;
}

.form-rows5 {
  border: 1px solid lightgreen;
  margin-bottom: 15px;
  padding: 5px;
}

.form-rows6 {
  border: 1px solid pink;
  margin-bottom: 15px;
  padding: 5px;
}

.form-rows7 {
  border: 1px solid plum;
  margin-bottom: 15px;
  padding: 5px;
}

.form-rows8 {
  border: 1px solid lightblue;
  margin-bottom: 15px;
  padding: 5px;
}

.form-rows9 {
  border: 1px solid aliceblue;
  margin-bottom: 15px;
  padding: 5px;
}

.v-select {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: 600 !important;
  color: #edefff;
}

@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
